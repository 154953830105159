import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { AttachmentComponent as AttachmentComponentBase } from '@web/base/shared/components/attachment/attachment.component';

@Component({
  selector: 'web-attachment',
  templateUrl: './../../../../base/shared/components/attachment/attachment.component.html',
  styleUrls: ['./../../../../base/shared/components/attachment/attachment.component.scss'],
})

export class AttachmentComponent extends AttachmentComponentBase {
  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public sanitizer: DomSanitizer
  ) {
    super(translate, snackbar, sanitizer);
  }
}
