import { Component, OnInit, Input } from '@angular/core';
import { order } from 'utils';
import { IAttachment } from '@web/project/shared/models/attachment.model';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { VideoDialogComponent } from '@web/project/shared/components/videos-module/video-dialog/video-dialog.component';
import { EmbedVideoService } from '@web/project/shared/components/videos-module/embed-video-service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'web-base-attachments-module',
  templateUrl: './attachments-module.component.html',
  styleUrls: ['./attachments-module.component.scss']
})
export class AttachmentsModuleComponent implements OnInit {

  @Input() attachments: IAttachment[];
  @Input() title: string;
  @Input() order: 'asc' | 'desc' = 'asc';
  @Input() showTitle: boolean;

  ATTACHMENT_TYPE_VIDEO = ConstantsProject.ATTACHMENT_TYPE_VIDEO;

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public embedVideoService: EmbedVideoService
  ) { }

  ngOnInit() {
    if (this.order === 'asc') {
      this.attachments.sort((a, b) => order(a, b));
    } else {
      this.attachments.sort((a, b) => order(a, b, 'order', 'desc'));
    }
  }

  loadVideo(video: any) {
    this.dialog.open(VideoDialogComponent, {
      panelClass: ['dialog', 'dialog-sm', 'video-dialog'],
      data: {
        title: video.title,
        video: this.sanitizer.bypassSecurityTrustResourceUrl(this.embedVideoService.embed_video(video.videoUrl))
      },
      width: '75%'
    });
  }
}
