import { Component } from '@angular/core';
import { AttachmentsHandleComponent as AttachmentsHandleComponentBase } from '@web/base/shared/components/attachments-handle/attachments-handle.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { AttachmentsHandleDialogService } from './dialogs/attachments-handle-dialog.service';

@Component({
  selector: 'web-attachments-handle',
  templateUrl: './../../../../base/shared/components/attachments-handle/attachments-handle.component.html',
  styleUrls: ['./../../../../base/shared/components/attachments-handle/attachments-handle.component.scss'],
  providers: [AttachmentsHandleDialogService]
})

export class AttachmentsHandleComponent extends AttachmentsHandleComponentBase { 
  
  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public attachmentsHandleDialogService: AttachmentsHandleDialogService
  ) {
    super(translate, snackbar, attachmentsHandleDialogService);
  }

}
