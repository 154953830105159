import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'baseTruncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit, trail): string {
    let trimmedString = value;

    limit = limit ? limit : 10;
    trail = '';

    if (!value) {
      return '';
    } else if (value.length > limit) {
      trail = trail ? trail : '...';
      trimmedString = value.substr(0, limit);
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
    }

    return trimmedString + trail;
  }
}
