import { Component, OnInit, Input } from "@angular/core";
import { BaseComponent } from "utils";
import { TranslateService } from "@ngx-translate/core";
import { CoreService } from "../../core.service";

@Component({
  selector: "web-banners-sidenav",
  templateUrl: "./banners-sidenav.component.html",
  styleUrls: ["./banners-sidenav.component.scss"]
})
export class BannersSidenavComponent extends BaseComponent implements OnInit {
  @Input() banners;

  constructor(translate: TranslateService, public coreService: CoreService) {
    super(translate);
  }

  ngOnInit() {
    // this.subscriptions.push(this.coreService.bannersSidenav$.subscribe(res => {
    //   if (res) { this.banners = res; }
    // }));
  }
}
