import { SharedService } from '@web/project/shared/shared.service';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Component, OnInit, ChangeDetectorRef, HostListener, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { IBreadCrumb } from '@web/project/shared/models/breadcrumb.model';

@Component({
  selector: 'web-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseComponent implements OnInit {

  public innerHeight: number;

  public isBrowser;
  public fixed = false;
  public offsetHeader = ConstantsProject.HEADER_HEIGHT;

  public breadcrumbs: Array<IBreadCrumb> = [];

  public translated = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  constructor(
    public translate: TranslateService,
    public sharedService: SharedService,
    public sc: ScrollDispatcher,
    public ref: ChangeDetectorRef,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    super(translate);

    this.innerHeight = window.innerHeight;
  }

  ngOnInit() {
    this.subscriptions.push(this.sharedService.reloadBreadcrumbs$.subscribe((breadcrumbs: Array<IBreadCrumb>) => {
      this.breadcrumbs = breadcrumbs;
    }));

    // this.subscriptions.push(this.sharedService.reloadBreadcrumbs$.subscribe((route: ActivatedRoute) => {
    //   this.buildBreadCrumb(route);
    // }));

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    //   distinctUntilChanged(),
    //   map(event => this.buildBreadCrumb(this.activatedRoute.root))).subscribe(() => {});

    //   this.getTranslations(['routes']).then(() => {
    //     if (!this.translated) {
    //       this.translated = true;
    //       this.breadcrumbs.map(b => {
    //         b.label = this.translations['routes'][b.label] || b.label;
    //         return b;
    //       });
    //     }
    //   });

    // const self = this;

    this.sc.scrolled().subscribe(() => {
      // self.fixed = (scrollY >= this.offsetHeader && self.innerHeight > ConstantsProject.LIMIT_HEIGHT_TO_FIXED_MENU);
      // self.ref.detectChanges();
      this.fixed = (scrollY >= this.offsetHeader && this.innerHeight > ConstantsProject.LIMIT_HEIGHT_TO_FIXED_MENU);
      this.ref.detectChanges();
    });
  }

  // buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<IBreadCrumb> = []) {
  //   // If no routeConfig is avalailable we are on the root path

  //   let newBreadcrumbs = [];
  //   let nextUrl = '';

  //   if (route.routeConfig && route.routeConfig.data && !route.routeConfig.data['hidden']) {

  //     let label;

  //     if (this.translations['routes']) {
  //       this.translated = true;

  //       label = this.translations['routes'][route.routeConfig.data['section']] || route.routeConfig.data['section'];
  //     } else {
  //       label = route.routeConfig.data['section'];
  //     }

  //     const path = route.routeConfig.path;

  //     if (route.parent && !url) {
  //       nextUrl += route.parent.routeConfig.path;
  //     }

  //     nextUrl += `${url}${path}/`;

  //     if (Object.keys((<BehaviorSubject<any>>route.params).value).length) {
  //       const params = (<BehaviorSubject<any>>route.params).value;

  //       Object.keys(params).forEach(k => {
  //         nextUrl = nextUrl.replace(':' + k + '/', params[k] + '/');
  //       });
  //     }

  //     const breadcrumb = {
  //       label: label,
  //       url: nextUrl,
  //       navigable: route.routeConfig.data['navigable'] !== false,
  //     };
  //     newBreadcrumbs = [...breadcrumbs, breadcrumb];
  //   } else {
  //     newBreadcrumbs = [...breadcrumbs];
  //   }

  //   if (route.firstChild) {
  //     // If we are not on our current path yet,
  //     // there will be more children to look after, to build our breadcumb
  //     return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
  //   }

  //   this.breadcrumbs = newBreadcrumbs;
  // }
}
