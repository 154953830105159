import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { getCookie, setCookie } from '@web/base/shared/utils';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss']
})
export class PopupDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PopupDialogComponent>) {
    const image = this.data.popup.files.find(f => f.name === 'image');
    if (image) {
      this.data.popup.img = image.url;
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  hide7days() {
    if (getCookie(ConstantsProject.COOKIES_PREFIX + 'aceptar_cookies')) {
      setCookie(ConstantsProject.COOKIES_PREFIX + 'popup_' + this.data.popup.popupID, this.data.popup.popupID, 7);
    }
    this.dialogRef.close();
  }

  hide4ever() {
    if (getCookie(ConstantsProject.COOKIES_PREFIX + 'aceptar_cookies')) {
      setCookie(ConstantsProject.COOKIES_PREFIX + 'popup_' + this.data.popup.popupID, this.data.popup.popupID, 99999);
    }
    this.dialogRef.close();
  }
}
