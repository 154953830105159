import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { Renderer2 } from '@angular/core';

import { CoreService } from './../../core.service';
import { MenuService } from './../menu.service';
import { MenuItem } from '../menu-item.model';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'web-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuMobileComponent extends BaseComponent implements OnInit {

  @ViewChild('mainMenu', { static: true }) mainMenu: ElementRef;

  public menuMobile: MenuItem[];

  constructor(
    public translate: TranslateService,
    public renderer: Renderer2,
    public coreService: CoreService,
    public menuService: MenuService) {
    super(translate);
  }

  ngOnInit() {
    this.getTranslations(['menu']).then(() => {
      this.menuMobile = this.menuService.getMenuMobile();
    });
  }

  onClickItem(event) {
    this.toggleItem(event.index, event.menu);
  }

  toggleItem(index, menu = null) {
    if (!menu) { menu = this.mainMenu.nativeElement; }
    let i = 0;
    for (const li of menu.children) {
      if (i === index) {
        // Cambiar el estado del item origen
        const status = li.attributes['status'].value;
        switch (status) {
          case 'active':
            this.deactivateItem(li);
            this.closeChildren(li);
            break;
          case 'inactive':
            this.activateItem(li);
            const _submenu = li.children[1].children[0];
            this.activateItem(_submenu);
            break;
        }
      } else {
        // Cerrar el resto de hermanos que tengan status=active
        if (li.attributes['status'] !== undefined && li.attributes['status'].value === 'active') {
          this.deactivateItem(li);
          this.closeChildren(li);
        }
      }
      i++;
    }
  }

  closeChildren(item) {
    const submenu = item.children[1].children[0];
    this.renderer.setAttribute(submenu, 'status', 'inactive');
    this.renderer.removeClass(submenu, 'active');
    this.renderer.addClass(submenu, 'inactive');

    const children = item.children[1].children[0].children;
    let i = 0;
    for (const li of children) {
      if (li.children.length > 1 && li.attributes['status'].value === 'active') {
        this.renderer.setAttribute(li, 'status', 'inactive');
        this.renderer.removeClass(li, 'active');
        this.renderer.addClass(li, 'inactive');
        this.closeChildren(li);
      }
      i++;
    }
  }

  activateItem(item) {
    this.renderer.setAttribute(item, 'status', 'active');
    this.renderer.removeClass(item, 'inactive');
    this.renderer.addClass(item, 'active');
  }

  deactivateItem(item) {
    this.renderer.setAttribute(item, 'status', 'inactive');
    this.renderer.removeClass(item, 'active');
    this.renderer.addClass(item, 'inactive');
  }

  closeAllMenu() {
    let i = 0;
    for (const li of this.mainMenu.nativeElement.children) {
      if (li.children.length > 1 && li.attributes['status'].value === 'active') {
        this.toggleItem(i);
      }
      i++;
    }
  }

  closeSidenav() {
    this.closeAllMenu();
    this.coreService.setSidenavStatus(false);
  }
}
