import { Component, ViewEncapsulation } from '@angular/core';
import { MenuMobileComponent as MenuMobileComponentBase } from '@web/base/core/menu/menu-mobile/menu-mobile.component';

@Component({
  selector: 'web-project-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: [
    // './../../../../base/core/menu/menu-mobile/menu-mobile.component.scss',
    './menu-mobile.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class MenuMobileComponent extends MenuMobileComponentBase {

}
