import { Component, OnInit } from '@angular/core';
import { HeaderComponent as HeaderComponentBase } from '@web/base/core/header/header.component';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from '@web/base/core/core.service';
import { AuthService } from '@web/base/auth/auth.service';
import { ScrollDispatcher } from '@angular/cdk/overlay';
// import { SigninService } from './dialogs/signin/signin.service';
import { SigninService } from '@web/base/core/header/dialogs/signin/signin.service';
import { Router } from '@angular/router';


@Component({
  selector: 'web-project-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends HeaderComponentBase {

  backgroundImagesList = [
    '../../../../assets/img/header.jpg',
    // '../../../../assets/img/header2.png',
    // '../../../../assets/img/header3.png',
    // '../../../../assets/img/header4.png',
    // '../../../../assets/img/header5.png',
  ];

  constructor(
    public translate: TranslateService,
    public coreService: CoreService,
    public authService: AuthService,
    public signinService: SigninService,
    public router: Router,
    public sc: ScrollDispatcher) {
    super(translate, coreService, authService, signinService, router, sc);
  }

}
