import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { AttachmentsComponent as AttachmentsComponentBase } from '@web/base/shared/components/attachments/attachments.component';

@Component({
  selector: 'web-attachments',
  templateUrl: './../../../../base/shared/components/attachments/attachments.component.html',
  styleUrls: ['./../../../../base/shared/components/attachments/attachments.component.scss'],
})
export class AttachmentsComponent extends AttachmentsComponentBase {

  constructor(
    public translate: TranslateService
  ) {
    super(translate);
  }
}
