import { publicS3Url } from './../../../../environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'utils';
import { SwUpdate } from '@angular/service-worker';
import { SharedService } from '@web/project/shared/shared.service';
import { getCookie } from '@web/base/shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription, BehaviorSubject } from 'rxjs';
import { IResponse } from '@web/project/shared/models/response.model';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { order } from 'utils';
import * as moment from 'moment';
import { CalendarComponent } from '@web/project/shared/components/calendar/calendar.component';
import { Moment } from 'moment';
import { CoreService } from '@web/base/core/core.service';
import { MenuService } from '@web/base/core/menu/menu.service';
import { PopupDialogComponent } from '@web/base/core/popup-dialog/popup-dialog.component';

@Component({
  selector: 'web-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent extends BaseComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  public showCookiesInfo = false;
  public showSidenav = true;
  public showContent = false;

  public sidenavStatusSubscription: Subscription;

  public banners = [];
  public bannersHeader = [];
  public bannersFooter = [];
  public bannersSidenav = [];
  // public valuesCalendar = [];

  public events: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public events$ = this.events.asObservable();
  // public oldMonth: string;

  public loadingEvents: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public loadingEvents$ = this.loadingEvents.asObservable();

  // public valuesCalendar = [
  //   {
  //     color: '#4dc1f1',
  //     startDate: '2019-11-01',
  //     endDate: '2019-11-02',
  //     title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus maximus dictum risus',
  //     titleCategory: 'Noticias',
  //     url: 'prueba/2'
  //   },
  //   {
  //     color: '#d8d2d2',
  //     startDate: '2019-11-10',
  //     endDate: '2019-11-20',
  //     title: 'Revista ViaFisio',
  //     titleCategory: 'Publicaciones científicas',
  //     url: 'prueba/32'
  //   }
  // ];

  @ViewChild('calendarObj', { static: false })
  calendarObj: CalendarComponent;

  BANNER_TOP = ConstantsProject.BANNER_TOP;
  BANNER_BOTTOM = ConstantsProject.BANNER_BOTTOM;
  BANNER_SIDE = ConstantsProject.BANNER_SIDE;

  public publicS3Url = publicS3Url;

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public coreService: CoreService,
    public menuService: MenuService,
    public swUpdate: SwUpdate,
    public route: ActivatedRoute,
    public router: Router,
    public breakpointObserver: BreakpointObserver,
  ) {
    super(translate, null, dialog);

    breakpointObserver.observe(['(max-width: 959px)']).subscribe(res => {
      this.coreService.setIsMobile(res.matches);
      this.coreService.setSidenavStatus(false);
    });
  }

  ngOnInit() {
    // this.oldMonth = moment().format('MM');

    this.subscriptions.push(
      this.sharedService.showSidenav$.subscribe(v => {
        setTimeout(() => (this.showSidenav = v));
      })
    );

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(evt => {
        console.log('service worker updated');
      });

      this.swUpdate
        .checkForUpdate()
        .then(() => {
          // noop
        })
        .catch(err => {
          console.error('error when checking for update', err);
        });
    }

    this.getTranslations(['core']);

    this.menuService.createMenu();

    this.subscriptions.push(
      this.coreService.templateLoaded$.subscribe((v: IResponse) => {

        // v.result.calendar.map(e => {
        //   e.date = moment(e.date);
        //   return e;
        // });

        if (v) {
          this.events.next(v.result.calendar);
          // this.calendarObj.today();

          this.bannersFooter = v.result.banners
            .filter(b => b.bannerPositionID === this.BANNER_BOTTOM)
            .sort((a, b) => order(a, b));

          this.bannersSidenav = v.result.banners
            .filter(b => b.bannerPositionID === this.BANNER_SIDE)
            .sort((a, b) => order(a, b));

          if (v.result.user && !v.result.user.conditionsAccepted) {
            if (v.result.user.elementTypeID === ConstantsProject.ELEMENT_TYPE_COLLEGIATE) {
              this.router.navigate(['mi-zona-privada', 'primer-acceso']);
            } else if (v.result.user.elementTypeID === ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE) {
              this.router.navigate(['mi-zona-privada-precolegial', 'primer-acceso']);
            }
          }

          // ------------
          // Ventanas emergentes
          // ------------

          this.route.queryParams.subscribe((params: Params) => {
            const popupId = params['showPopupId'];
            if (popupId) {
              this.sharedService
                .loadPopup(popupId)
                .subscribe((res: IResponse) => {
                  this.dialog.open(PopupDialogComponent, {
                    panelClass: ['dialog', 'popup-dialog'],
                    width: '95%',
                    maxWidth: '750px',
                    data: { popup: res.result }
                  });
                });
            } else {
              v.result.popups.map(p => {
                if (
                  p &&
                  !getCookie(
                    ConstantsProject.COOKIES_PREFIX + 'popup_' + p.popupID
                  )
                ) {
                  this.dialog.open(PopupDialogComponent, {
                    panelClass: ['dialog', 'popup-dialog'],
                    width: '95%',
                    maxWidth: '750px',
                    data: { popup: p }
                  });
                }
              });
            }
          });
        }
      })
    );

    this.coreService.loadTemplate();

    // Cerrar el sidenav si pasamos de 'resolución movil' a PC
    this.sidenavStatusSubscription = this.coreService
      .getSidenavStatus()
      .subscribe(status => {
        if (status === false) {
          this.sidenav.close();
        } else {
          this.sidenav.open();
        }
      });

    // aviso Cookies
    this.showContent = true;

    if (!getCookie(ConstantsProject.COOKIES_PREFIX + 'aceptar_cookies')) {
      this.showCookiesInfo = true;
    }
  }

  openCookiesPolicy() {
    this.openSimpleDialog(
      this.translations['core'].cookiesPolicy,
      this.translations['core'].cookiesPolicyContent
    );
  }

  onSidenavClosedStart() {
    this.coreService.setSidenavStatus(false);
  }

  onSidenavOpenedStart() {
    this.coreService.setSidenavStatus(true);
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  dateSelected(value: Moment) {
    if (value) {
      this.loadCalendar(value);
    }
    // if (value && (value.format('MM') !== this.oldMonth)) {
    //   this.oldMonth = value.format('MM');
    //   this.loadCalendar(value);
    // }
  }

  loadCalendar(value: Moment) {
    this.events.next([]);
    setTimeout(() => this.loadingEvents.next(true) );
    this.coreService.loadEventsByMonthAndYear(+value.format('MM'), +value.format('YYYY')).subscribe((res: IResponse) => {
      this.events.next(res.result);
      setTimeout(() => this.loadingEvents.next(false) );
    }, err => {
        console.log('err', err);
        setTimeout(() => this.loadingEvents.next(false) );
      }
    );
  }


}
