import { HomeComponent as HomeComponentBase } from '@web/base/core/home/home.component';
import { Component } from '@angular/core';

@Component({
  selector: 'web-project-home',
  templateUrl: './home.component.html',
  styleUrls: ['./../../../base/core/home/home.component.scss']
})
export class HomeComponent extends HomeComponentBase {
  
}
