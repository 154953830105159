import { SigninComponent } from './signin.component';
import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { SigninService as SigninServiceBase } from '@web/base/core/header/dialogs/signin/signin.service';

@Injectable()
export class SigninService extends SigninServiceBase {

  openDialog() {
    const config = new MatDialogConfig();
    this.dialogRef = this.dialog.open(SigninComponent, { panelClass: 'dialog' });
    return this.dialogRef.afterClosed();
  }

}
