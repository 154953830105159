import { EditAttachmentComponent } from './components/attachments-handle/dialogs/edit-attachment/edit-attachment.component';
import { VideoDialogComponent } from './components/videos-module/video-dialog/video-dialog.component';
import { ImageGalleryComponent } from './components/images-module/image-gallery/image-gallery.component';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { SectionTitleComponent } from './components/section-title/section-title.component';
import { SharedService } from './shared.service';
import { BaseWebComponent } from './components/base-web/base-web.component';
import { BaseListWebComponent } from './components/base-list-web/base-list-web.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { ImagesModuleComponent } from './components/images-module/images-module.component';
import { AttachmentsModuleComponent } from './components/attachments-module/attachments-module.component';
import { VideosModuleComponent } from './components/videos-module/videos-module.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { AttachmentsHandleComponent } from './components/attachments-handle/attachments-handle.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { ImagesFadingComponent } from './components/images-fading/images-fading.component';
import { SharedCommonModule } from '@web/base/shared/shared-common.module';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { GetInitialsPipe } from './pipes/initials.pipe';
import { YesNoPipe } from './pipes/yesNo.pipe';
import { TrueFalsePipe } from './pipes/trueFalse.pipe';
import { ImageGalleryService } from './components/images-module/image-gallery/service/image-gallery.service';
import { EmbedVideoService } from './components/videos-module/embed-video-service';
import { AuthGuard } from '@web/base/auth/auth.guard';
import { AuthCollegiateGuard } from '@web/base/auth/auth-collegiate.guard';
import { AuthPrecollegiateGuard } from '@web/base/auth/auth-precollegiate.guard';
import { PaymentTpvComponent } from './components/payment-tpv/payment-tpv.component';
import { ElementSearchInputComponent } from './components/element-search-input/element-search-input.component';
import { TableComponent } from './components/table/table.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { SearchElementComponent } from './components/element-search-input/dialogs/search-element/search-element.component';
import { CurrencyPipe, DatePipe } from '@angular/common';

@NgModule({
  imports: [
    SharedCommonModule,
  ],
  declarations: [
    BaseWebComponent,
    BaseListWebComponent,
    AttachmentsComponent,
    ImagesModuleComponent,
    AttachmentsModuleComponent,
    VideosModuleComponent,
    FileInputComponent,
    CalendarComponent,
    AttachmentsHandleComponent,
    LoadingButtonComponent,
    AttachmentComponent,
    ImagesFadingComponent,
    SectionTitleComponent,
    ImageGalleryComponent,
    VideoDialogComponent,
    EditAttachmentComponent,
    PaymentTpvComponent,
    TableComponent,
    NoResultsComponent,
    ElementSearchInputComponent,
    SearchElementComponent,
    SafeHtmlPipe,
    FileSizePipe,
    TruncatePipe,
    SlugifyPipe,
    GetInitialsPipe,
    YesNoPipe,
    TrueFalsePipe,
  ],
  entryComponents: [
    ImageGalleryComponent,
    VideoDialogComponent,
    EditAttachmentComponent,
    SearchElementComponent,
  ],
  exports: [
    SharedCommonModule,
    BaseWebComponent,
    BaseListWebComponent,
    AttachmentsComponent,
    ImagesModuleComponent,
    AttachmentsModuleComponent,
    VideosModuleComponent,
    FileInputComponent,
    CalendarComponent,
    AttachmentsHandleComponent,
    LoadingButtonComponent,
    AttachmentComponent,
    ImagesFadingComponent,
    SectionTitleComponent,
    FlexLayoutModule,
    MatButtonModule,
    ImageGalleryComponent,
    VideoDialogComponent,
    EditAttachmentComponent,
    PaymentTpvComponent,
    TableComponent,
    NoResultsComponent,
    ElementSearchInputComponent,
    SearchElementComponent,
    SafeHtmlPipe,
    FileSizePipe,
    TruncatePipe,
    SlugifyPipe,
    GetInitialsPipe,
    YesNoPipe,
    TrueFalsePipe,
  ],
  providers: [
    SafeHtmlPipe,
    FileSizePipe,
    TruncatePipe,
    SlugifyPipe,
    CurrencyPipe,
    DatePipe,
    GetInitialsPipe,
    YesNoPipe,
    TrueFalsePipe,
    ImageGalleryService,
    EmbedVideoService,
    AuthGuard,
    AuthCollegiateGuard,
    AuthPrecollegiateGuard,
  ]
})

export class SharedModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [SharedService]
    };
  }

  static forChild(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
