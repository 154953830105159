import { MenuItem } from './../../base/core/menu/menu-item.model';
import { Constants } from '@web/base/shared/constants.class';
import { SSL_OP_TLS_ROLLBACK_BUG } from 'constants';

export class ConstantsProject extends Constants {

  static TOKEN_KEY = '1df6b43992e8f9387a0642982d5ebce8';
  static COOKIES_PREFIX = '_1df6b43992e8f9387a0642982d5ebce8_';
  static COOKIES_CONSENT = '__LogopedasRiojaCookiesConsent';

  static PROJECT_LOCATION_MAIN = { lat: 42.460448, lng: -2.450229 };

  static HEADER_HEIGHT = 230;

  static OFFICE_ADDRESSES = [
    {
      id: 1,
      office: 'Colegio Profesional de Logopedas de La Rioja',
      info: {
        address: 'C/ María Teresa Gil de Gárate 49 Bajo - 26003 Logroño (La Rioja)',
        phone: 'Tlf.: 669 63 02 31 / info@colegiologopedaslarioja.org'
      }
    }
  ];

  static MENU_SIDENAV: Array<MenuItem> = [];

  static MENU_HEADER: Array<MenuItem> = [
    { title: 'home', url: '/', icon: 'home' },
    {
      title: 'college',
      icon: 'domain',
      children: [
        { title: 'location', url: '/colegio/localizacion' },
        { title: 'board', url: '/colegio/junta-de-gobierno' },
        {
          title: 'membership', separator: true, children: [
            { title: 'membershipRequirements', url: 'p/91/requisitos-colegiacion' },
            { title: 'transfersRequirements', url: 'p/92/requisitos-traslado' },
            { title: 'dismissalRequirements', url: 'p/93/requisitos-baja' }
          ]
        },
        { title: 'contact', url: '/contacto', separator: true }
      ]
    },
    {
      title: 'collegiates',
      icon: 'person_outline',
      children: [
        { title: 'employment', url: '/bolsa-de-empleo', private: true },
        { title: 'circulars', url: 'contenidos/25/circulares', private: true },
        { title: 'formation', url: '/cursos' },
        { title: 'adviceSupport', url: 'p/100/contenido' },
      ]
    },
    {
      title: 'services',
      icon: 'favorite_border',
      children: [
        { title: 'faqs', url: 'servicios/preguntas-frecuentes' },
        // { title: 'newsletters', url: '/boletin-digital' },
        { title: 'links', url: '/enlaces-de-interes' },
      ]
    },
    {
      title: 'publications',
      icon: 'import_contacts',
      children: [
        { title: 'news', url: '/contenidos/1/noticias' },
        { title: 'deontologicalCode', url: 'p/98/codigo-deontologico' },
        { title: 'documentsOfInterest', url: 'p/99/documentos-de-interes' },
      ]
    },
    {
      title: 'legislation',
      icon: 'import_contacts',
      children: [
        { title: 'creationLaw', url: 'p/94/ley-creacion' },
        { title: 'statutes', url: 'p/95/estatutos' },
        { title: 'lawsOfOurProfession', url: 'p/96/leyes-de-nuestra-profesion' },
        { title: 'regulations', url: 'p/97/normativas' },
      ]
    },
    {
      title: 'virtualOffice',
      url: '/ventanilla-unica',
      icon: 'tv',
      children: [
        { title: 'professionalDirectory', url: '/ventanilla-unica/censo-de-colegiados' },
        { title: 'whyCollegiate', url: '/ventanilla-unica/por-que-colegiarse' },
        { title: 'registerCollegiate', url: '/ventanilla-unica/alta-colegial' },
        { title: 'complaints', url: '/quejas-reclamaciones-sugerencias' },
        { title: 'memories', url: '/contenidos/6/memorias', private: true, separator: true },
        { title: 'certificatesDownload', url: '/ventanilla-unica/descarga-de-certificados', private: true }
      ]
    }
  ];

  constructor() {
    super();
  }
}
