import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { BaseComponent, SimpleSnackbarComponent, downloadURL } from 'utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AttachmentsHandleDialogService } from './dialogs/attachments-handle-dialog.service';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { IAttachment } from '@web/project/shared/models/attachment.model';

@Component({
  selector: 'web-base-attachments-handle',
  templateUrl: './attachments-handle.component.html',
  styleUrls: ['./attachments-handle.component.scss'],
  providers: [AttachmentsHandleDialogService]
})

export class AttachmentsHandleComponent extends BaseComponent implements OnInit {

  @Input() formData: FormData;
  @Input() attachments: Array<IAttachment>;
  @Input() canActive = true;
  @Input() acceptedFiles = '';
  @Input() acceptedFilesReadable = '';
  @Input() maxFiles;

  public maxSizeAttachment = ConstantsProject.MAX_SIZE_ATTACHMENTS;
  public ATTACHMENT_TYPE_FILE = ConstantsProject.ATTACHMENT_TYPE_FILE;
  public ATTACHMENT_TYPE_IMAGE = ConstantsProject.ATTACHMENT_TYPE_IMAGE;
  public ATTACHMENT_TYPE_VIDEO = ConstantsProject.ATTACHMENT_TYPE_VIDEO;

  public maxSizeReadable = this.maxSizeAttachment / 1024 / 1024;

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public attachmentsHandleDialogService: AttachmentsHandleDialogService
  ) {
    super(translate, snackbar);
  }

  ngOnInit() {

  }

  openDialog(index?: number) {
    if (index === undefined && this.maxFiles !== undefined && this.attachments.length === this.maxFiles) {
      this.openSnackbar(this.translations['general']['maxFilesError'], SimpleSnackbarComponent.TYPE_ERROR);
    } else {
      index = index === undefined ? this.attachments.length : index;

      this.attachmentsHandleDialogService.openDialogAttachment(
        index,
        this.formData,
        // attachment,
        this.attachments,
        this.canActive,
        this.acceptedFiles,
      ).subscribe((res: { attachment: IAttachment, data: FormData } | boolean) => { });
    }
  }

  download(attachment: IAttachment) {
    downloadURL(attachment.url, attachment.title, true);
  }

  del(index: number, attachment: IAttachment) {
    if (attachment.hash) {
      attachment.delete = !attachment.delete;
    } else {
      this.attachments.splice(index, 1);

      if (this.formData.has('file_' + index)) {
        this.formData.delete('file_' + index);
      }
    }
  }
}

