import { Component, OnInit, Input } from '@angular/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { Observable } from 'rxjs';
import { BaseListWebService } from '@web/project/shared/components/base-list-web/base-list-web.service';
import { IBaseListWebItem } from '@web/project/shared/models/base-list-web-item.model';
import { BaseListWebComponent } from '@web/project/shared/components/base-list-web/base-list-web.component';

@Component({
  selector: 'web-home-slider-content',
  templateUrl: './../../../shared/components/base-list-web/base-list-web.component.html',
  styleUrls: [
    './../../../shared/components/base-list-web/base-list-web.component.scss',
    './slider-content.component.scss'
  ],
  providers: [BaseListWebService]
})
export class SliderContentComponent extends BaseListWebComponent implements OnInit {
  @Input() content$: Observable<Array<IBaseListWebItem>>;

  itemKeyName = 'elementID';
  showCategory = true;
  showSectionTitle = false;
  showCategoryText = false;
  paginate = false;

  disposition: number = ConstantsProject.DISPOSITION_CAROUSEL;

  getData() {
    this.subscriptions.push(
      this.content$.subscribe(content => {
        this.items$.next(
          content.map(c => {
            const image = c.files.find(f => f.name === 'image');
            c.image = image && image.url ? image.url : null;
            c.backgroundImg = c.image
              ? this.sanitizer.bypassSecurityTrustStyle('url(' + c.image + ')')
              : this.sanitizer.bypassSecurityTrustStyle('url(./../../../../../assets/img/project/default.jpg)');

            return c;
          })
        );
      })
    );
  }

  goToDetail(item: any) {
    this.router.navigate([item.url]);
  }
}
