export function getCookie(cookieName: string) {
  let keyValue;
  keyValue = document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)');
  return keyValue ? keyValue[2] : null;
}

export function setCookie(cookieName: string, value: string, days: number, domain?: string) {
  const expires = new Date();
  expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = cookieName + '=' + value + ';expires=' + expires.toUTCString() + ';path=/;' + (domain ? ';Domain=domain' : null);
}

export function deleteCookie(cookieName: string, domain?: string) {
  document.cookie = cookieName + '=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/;' + (domain ? ';Domain=domain' : null);
}
