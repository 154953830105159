import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterState, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Injectable()
export class AuthPrecollegiateGuard {

  constructor(public authService: AuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    this.authService.isLoggedIn().pipe(
      tap(v => {
        if (v === false || (this.authService.user && this.authService.user.elementTypeID !== ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE)) {
          this.router.navigate(['login'], { queryParams: { returnUrl: routerState.url } });
        }
      }
      )).subscribe();

    return this.authService.isLoggedIn();
  }

  canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    this.authService.isLoggedIn().pipe(
      tap(v => {
        if (v === false || (this.authService.user && this.authService.user.elementTypeID !== ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE)) {
          this.router.navigate(['login'], { queryParams: { returnUrl: routerState.url } });
        }
      }
      )).subscribe();

    return this.authService.isLoggedIn();
  }
}
