import { SharedService } from '@web/project/shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { Component } from '@angular/core';
import { BaseWebComponent as BaseWebComponentBase } from '@web/base/shared/components/base-web/base-web.component';

@Component({
    template: ''
})
export class BaseWebComponent extends BaseWebComponentBase {
  constructor(
    public translate: TranslateService,
    public snackbar?: MatSnackBar,
    public dialog?: MatDialog,
    public meta?: Meta,
    public title?: Title,
    public route?: ActivatedRoute,
    public sharedService?: SharedService) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  setMetas(title, description?) {
    setTimeout(() => {
      if (this.translations['general']) {
        this.title.setTitle(this.translations['general']['projectName'] + ' :: ' + title);
        this.meta.updateTag({
          name: 'description', content: description ? description : title
        });
      }
    }, 50);
  }
}
