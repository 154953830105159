import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Error403Component } from '@web/base/core/error-403/error-403.component';
import { Error404Component } from '@web/base/core/error-404/error-404.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '403', component: Error403Component },
  { path: '404', component: Error404Component },
  { path: 'home', component: HomeComponent, data: { section: 'home' }, },
  {
    path: '',
    loadChildren: () => import('../../project/web/web.module').then(m => m.WebModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  // providers: [AuthGuard]
})
export class CoreRoutingModule {

}

