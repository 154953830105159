import { OverlayRef } from '@angular/cdk/overlay';
import { ImageGalleryComponent } from './image-gallery.component';
import { ImageGalleryOverlayRef as ImageGalleryOverlayRefBase} from '@web/base/shared/components/images-module/image-gallery/image-gallery-overlay-ref';

export class ImageGalleryOverlayRef extends ImageGalleryOverlayRefBase {

    public componentInstance: ImageGalleryComponent;

    constructor(public overlayRef: OverlayRef) {
      super(overlayRef);
    }
}
