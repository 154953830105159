import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService as SharedServiceBase } from '@web/base/shared/shared.service';

@Injectable()
export class SharedService extends SharedServiceBase {
    constructor(
        public http: HttpClient
    ) {
        super(http);
    }
}
