import { Component, OnInit } from '@angular/core';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { SigninComponent as SigninComponentBase } from '@web/base/auth/signin/signin.component';

@Component({
  selector: 'web-projects-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends SigninComponentBase {

  public userTypes = [
    { name: 'collegiates', value: ConstantsProject.ELEMENT_TYPE_COLLEGIATE }
    // { name: 'precollegiates', value: ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE }
  ];

}
