import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchElementComponent as SearchElementComponentBase } from '@web/base/shared/components/element-search-input/dialogs/search-element/search-element.component';
import { SharedService } from '@web/project/shared/shared.service';

@Component({
  selector: 'web-search-element',
  templateUrl: './../../../../../../base/shared/components/element-search-input/dialogs/search-element/search-element.component.html',
  styleUrls: ['./../../../../../../base/shared/components/element-search-input/dialogs/search-element/search-element.component.scss']
})
export class SearchElementComponent extends SearchElementComponentBase {

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SearchElementComponent>,
    public sharedService: SharedService
  ) {
    super(translate, snackbar, dialog, dialogRef, sharedService);
  }
}
