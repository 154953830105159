import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { YesNoPipe as YesNoPipeBase } from '@web/base/shared/pipes/yesNo.pipe';

@Pipe({ name: 'yesNo' })
export class YesNoPipe extends YesNoPipeBase implements PipeTransform {
    constructor(public translate: TranslateService) {
        super(translate);
    }
}
