import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleHttpRequestInterceptor as HandleHttpRequestInterceptorBase } from '@web/base/shared/interceptors/handle-http-request.interceptor';

@Injectable()
export class HandleHttpRequestInterceptor extends HandleHttpRequestInterceptorBase {

  constructor() {
    super();
  }
}
