import { Router } from '@angular/router';
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'menu-sidenav-item',
  templateUrl: './menu-sidenav-item.component.html',
  styleUrls: ['./menu-sidenav-item.component.scss']
})
export class MenuSidenavItemComponent extends BaseComponent implements OnInit {

  @Input() items;

  constructor(
    public translate: TranslateService,
    public router: Router,
    public cd: ChangeDetectorRef) {
    super(translate);
  }

  ngOnInit() {
    this.getTranslations(['menu']);
  }

  closeMenu(submenu) {
    submenu.style.pointerEvents = 'none';
    setTimeout(() => {
      submenu.style.pointerEvents = 'auto';
    }, 500);
  }
}
