import { ImageGalleryService } from '@web/project/shared/components/images-module/image-gallery/service/image-gallery.service';

import { ImagesModuleComponent as ImagesModuleComponentBase } from '@web/base/shared/components/images-module/images-module.component';
import { Component } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'web-images-module',
  templateUrl: './../../../../base/shared/components/images-module/images-module.component.html',
  styleUrls: ['./../../../../base/shared/components/images-module/images-module.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate('250ms ease-in'))
    ])
  ]
})
export class ImagesModuleComponent extends ImagesModuleComponentBase {

  constructor(public galleryService: ImageGalleryService) {
    super(galleryService);
  }
}
