import { Pipe, PipeTransform } from '@angular/core';
import { FileSizePipe as FileSizePipeBase } from '@web/base/shared/pipes/file-size.pipe';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({ name: 'fileSize' })
export class FileSizePipe extends FileSizePipeBase implements PipeTransform { }