import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { FileInputComponent as FileInputComponentBase } from '@web/base/shared/components/file-input/file-input.component';

@Component({
  selector: 'web-file-input',
  templateUrl: './../../../../base/shared/components/file-input/file-input.component.html',
  styleUrls: ['./../../../../base/shared/components/file-input/file-input.component.scss']
})
export class FileInputComponent extends FileInputComponentBase {
  constructor(
    public translate: TranslateService,
    public dialog: MatDialog
  ) {
    super(translate, dialog);
  }
}
