import { MenuService } from './../menu.service';
import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, HostListener } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../menu-item.model';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { BaseComponent } from 'utils';

@Component({
  selector: 'web-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuHeaderComponent extends BaseComponent implements OnInit {

  public innerHeight: number;
  public offsetHeader = ConstantsProject.HEADER_HEIGHT;
  public fixed = false;
  public menuHeader: MenuItem[];

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerHeight = window.innerHeight;
  }

  constructor(
    public translate: TranslateService,
    public sc: ScrollDispatcher,
    public ref: ChangeDetectorRef,
    public menuService: MenuService,
  ) {
    super(translate);

    this.innerHeight = window.innerHeight;

  }

  ngOnInit() {
    this.getTranslations(['menu']).then(() => {
      this.menuHeader = this.menuService.getMenuHeader();
    });

    // const self = this;

    this.sc.scrolled().subscribe(() => {
      // console.log('scroll self', self);

      // self.fixed = (scrollY >= this.offsetHeader && self.innerHeight > ConstantsProject.LIMIT_HEIGHT_TO_FIXED_MENU);
      // self.ref.detectChanges();

      this.fixed = (scrollY >= this.offsetHeader && this.innerHeight > ConstantsProject.LIMIT_HEIGHT_TO_FIXED_MENU);
      this.ref.detectChanges();
    });
  }

}
