import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from '@web/project/shared/shared.service';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component } from '@angular/core';
import { IBreadCrumb } from '../../models/breadcrumb.model';

@Component({
    template: ''
})
export class BaseWebComponent extends BaseComponent implements OnInit {

    public section: string;
    public sectionTitle: string;
    public showSidenav = true;

    public breadcrumbs: Array<IBreadCrumb> = [];

    constructor(
        public translate: TranslateService,
        public snackbar?: MatSnackBar,
        public dialog?: MatDialog,
        public meta?: Meta,
        public title?: Title,
        public route?: ActivatedRoute,
        public sharedService?: SharedService) {

        super(translate, snackbar, dialog);
    }

    ngOnInit() {
        this.sharedService.showSidenav.next(this.showSidenav);

        this.setBreadcrumbs();

        if (this.section && this.meta && this.title) {
            const sectionCamelCase = this.section.split('.', 1)[0].replace(/-([a-z0-9])/g, function (g) { return g[1].toUpperCase(); });
            this.getTranslations([sectionCamelCase]).then(() => {
                if (this.translations[sectionCamelCase] && this.translations[sectionCamelCase]['metas']) {
                    this.setMetas(
                        this.translations[sectionCamelCase]['metas']['title'],
                        this.translations[sectionCamelCase]['metas']['description']);
                }
                if (this.translations[sectionCamelCase] && this.translations[sectionCamelCase]['title']) {
                    this.sectionTitle = this.translations[sectionCamelCase]['title'];
                }
            }).catch(err => {
                console.log('err get translations', err);
            });
        }
    }

    setMetas(title, description?) {
        setTimeout(() => {
            this.title.setTitle(this.translations['general']['projectName'] + ' :: ' + title);
            if (this.translations['general']) {
                this.meta.updateTag({
                    name: 'description', content: description ? description : title
                });
            }
        }, 50);
    }

    // setSection(title) {
    //     if (this.route && this.route.routeConfig && this.route.routeConfig.data) {
    //         this.route.routeConfig.data['section'] = title;
    //         this.sharedService.reloadBreadcrumbs.next(this.route.root);
    //     }
    // }

    setBreadcrumbs() {
        this.sharedService.reloadBreadcrumbs.next(this.breadcrumbs);
    }
}
