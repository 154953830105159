
import { Component, Renderer2 } from '@angular/core';
import { ImagesFadingComponent as ImagesFadingComponentBase } from '@web/base/shared/components/images-fading/images-fading.component';

@Component({
  selector: 'web-images-fading',
  templateUrl: './../../../../base/shared/components/images-fading/images-fading.component.html',
  styleUrls: ['./../../../../base/shared/components/images-fading/images-fading.component.scss'],
})
export class ImagesFadingComponent extends ImagesFadingComponentBase {
  constructor(
    public renderer: Renderer2,
  ) {
    super(renderer);
  }
}
