import { Component, OnInit, Inject, Optional } from '@angular/core';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isEmpty } from 'lodash';

@Component({
  selector: 'web-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss']
})
export class Error404Component extends BaseComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    @Optional() @Inject(RESPONSE) public response: any
  ) {
    super(translate);
  }

  ngOnInit() {
    if (isEmpty(this.response)) {
      return;
    }

    this.response.statusCode = 404;
  }
}
