import { ImageGalleryComponent } from './../image-gallery.component';
import { IMAGE_DATA, GALLERY_DATA, IMAGE_INDEX, GALLERY_COUNTER, IMAGE_ACTION_BUTTON } from './image-gallery-overlay.tokens';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { ImageGalleryOverlayRef } from './../image-gallery-overlay-ref';
import { ImageActionButton } from '@web/project/shared/models/image-action-button.model';
import { IImageGallery } from '@web/project/shared/models/image-gallery.model';
import { ImageGalleryService as ImageGalleryServiceBase} from '@web/base/shared/components/images-module/image-gallery/service/image-gallery.service';
import { Injectable, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';

interface ImageGalleryConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  image?: IImageGallery;
  gallery?: IImageGallery[];
  imageIndex?: number;
  counter?: boolean;
  actionButton?: ImageActionButton;
}

const DEFAULT_CONFIG: ImageGalleryConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'gallery-panel',
  image: null,
  gallery: null,
  imageIndex: 0,
  counter: true,
  actionButton: null
};

@Injectable()
export class ImageGalleryService extends ImageGalleryServiceBase {

  constructor(
    public injector: Injector,
    public overlay: Overlay) {
      super(injector, overlay);
    }

    public createOverlay(config: ImageGalleryConfig) {
      const overlayConfig = this.getOverlayConfig(config);
      return this.overlay.create(overlayConfig);
    }

    open(config: ImageGalleryConfig = {}) {
      const dialogConfig = { ...DEFAULT_CONFIG, ...config };
      const overlayRef = this.createOverlay(dialogConfig);
      const dialogRef = new ImageGalleryOverlayRef(overlayRef);
      const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

      dialogRef.componentInstance = overlayComponent;

      overlayRef.backdropClick().subscribe(_ => dialogRef.close());

      return dialogRef;
    }

    public getOverlayConfig(config: ImageGalleryConfig): OverlayConfig {
      const positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically();

      const overlayConfig = new OverlayConfig({
        hasBackdrop: config.hasBackdrop,
        backdropClass: config.backdropClass,
        panelClass: config.panelClass,
        scrollStrategy: this.overlay.scrollStrategies.block(),
        positionStrategy
      });

      return overlayConfig;
    }

    public createInjector(config: ImageGalleryConfig, dialogRef: ImageGalleryOverlayRef): PortalInjector {
      // Instantiate new WeakMap for our custom injection tokens
      const injectionTokens = new WeakMap();

      // Set custom injection tokens
      injectionTokens.set(ImageGalleryOverlayRef, dialogRef);
      injectionTokens.set(IMAGE_DATA, config.image);
      injectionTokens.set(GALLERY_DATA, config.gallery);
      injectionTokens.set(IMAGE_INDEX, config.imageIndex);
      injectionTokens.set(GALLERY_COUNTER, config.counter);
      injectionTokens.set(IMAGE_ACTION_BUTTON, config.actionButton);

      // Instantiate new PortalInjector
      return new PortalInjector(this.injector, injectionTokens);
    }

    public attachDialogContainer(overlayRef: OverlayRef, config: ImageGalleryConfig, dialogRef: ImageGalleryOverlayRef) {
      const injector = this.createInjector(config, dialogRef);
      const containerPortal = new ComponentPortal(ImageGalleryComponent, null, injector);
      const containerRef: ComponentRef<ImageGalleryComponent> = overlayRef.attach(containerPortal);

      return containerRef.instance;
    }
}
