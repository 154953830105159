import { BaseListWebService } from '@web/base/shared/components/base-list-web/base-list-web.service';
import { Component } from '@angular/core';
import { GridContentComponent as GridContentComponentBase } from '@web/base/core/home/grid-content/grid-content.component';

@Component({
  selector: 'web-project-home-grid-content',
  templateUrl: './../../../shared/components/base-list-web/base-list-web.component.html',
  styleUrls: ['./../../../shared/components/base-list-web/base-list-web.component.scss'],
  providers: [BaseListWebService]
})
export class GridContentComponent extends GridContentComponentBase {
  
}
