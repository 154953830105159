import { BaseListWebService as BaseListWebServiceBase} from '@web/base/shared/components/base-list-web/base-list-web.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BaseListWebService extends BaseListWebServiceBase {
    constructor(
        public http: HttpClient
    ) {
      super(http);
    }
}
