import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class EmbedVideoService {

    constructor(
        public http: HttpClient,
    ) { }

    public embed_video(url_video: string) {
        let id;
        const url = new URL(url_video);

        // Youtube
        id = this.detectYoutube(url);
        if (id) {
            return 'https://www.youtube.com/embed/' + id;
        }

        // Vimeo
        id = this.detectVimeo(url);
        if (id) {
            return 'https://player.vimeo.com/video/' + id;
        }

        // Dailymotion
        id = this.detectDailymotion(url);
        if (id) {
            return 'https://www.dailymotion.com/embed/video/' + id;
        }

        return '';
    }

    public detectYoutube(url: any): string {
        if (url.hostname.indexOf('youtube.com') > -1) {
            return url.search.split('=')[1];
        }

        if (url.hostname === 'youtu.be') {
            return url.pathname.split('/')[1];
        }

        return '';
    }

    public detectVimeo(url: any): string {
        return (url.hostname === 'vimeo.com') ? url.pathname.split('/')[1] : null;
    }

    public detectDailymotion(url: any): string {
        if (url.hostname.indexOf('dailymotion.com') > -1) {
            return url.pathname.split('/')[2].split('_')[0];
        }

        if (url.hostname === 'dai.ly') {
            return url.pathname.split('/')[1];
        }

        return '';
    }

    public url_image(url: any, quality?: string): any {
        let id;
        let q_youtube = 'default';
        let q_vimeo = 'thumbnail_small';
        let q_dailymotion = 'thumbnail_360_url';

        switch (quality) {
            case 'medium':
                q_youtube = 'mqdefault';
                q_vimeo = 'thumbnail_medium';
                q_dailymotion = 'thumbnail_480_url';
                break;
            case 'high':
                q_youtube = 'hqdefault';
                q_vimeo = 'thumbnail_large';
                q_dailymotion = 'thumbnail_720_url';
                break;
            case 'max':
                q_youtube = 'maxresdefault';
                q_vimeo = 'thumbnail_large';
                q_dailymotion = 'thumbnail_1080_url';
                break;
            case 'default':
            default:
                q_youtube = 'default';
                q_vimeo = 'thumbnail_small';
                q_dailymotion = 'thumbnail_360_url';
        }


        url = new URL(url);

        id = this.detectYoutube(url);
        if (id) {
            return this.embed_youtube_image(id, q_youtube);
        }

        id = this.detectVimeo(url);
        if (id) {
            return this.embed_vimeo_image(id, q_vimeo);
        }

        id = this.detectDailymotion(url);
        if (id) {
            return this.embed_dailymotion_image(id, q_dailymotion);
        }

        return Promise.resolve();
    }

    public embed_youtube_image(id: string, quality?: string): any {
        const result = 'https://img.youtube.com/vi/' + id + '/' + quality + '.jpg';
        return new Promise((resolve) => {
            resolve(result);
        });
    }

    public embed_vimeo_image(id: string, quality?: string): any {
        return new Promise(
            (resolve, reject) => {
                this.http.get('https://vimeo.com/api/v2/video/' + id + '.json').subscribe((res: any) => {
                    resolve(res[0][quality]);
                },
                    e => reject(e));
            });
    }

    public embed_dailymotion_image(id: string, quality?: string): any {
        return this.http.get('https://api.dailymotion.com/video/' + id + '?fields=' + quality)
            .pipe(map((res: any) => {
                return res[quality];
            }))
            .toPromise()
            .catch(error => console.log(error));
    }
}
