import { Component } from '@angular/core';
import { EmbedVideoService } from '@web/project/shared/components/videos-module/embed-video-service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { AttachmentsModuleComponent as AttachmentsModuleComponentBase } from '@web/base/shared/components/attachments-module/attachments-module.component';

@Component({
  selector: 'web-attachments-module',
  templateUrl: './../../../../base/shared/components/attachments-module/attachments-module.component.html',
  styleUrls: ['./../../../../base/shared/components/attachments-module/attachments-module.component.scss']
})
export class AttachmentsModuleComponent extends AttachmentsModuleComponentBase {

  constructor(
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public embedVideoService: EmbedVideoService
  ) {
    super(dialog, sanitizer, embedVideoService);
  }

}
