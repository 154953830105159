import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { handleRequest } from '../utils';
import { Injectable } from '@angular/core';

@Injectable()
export class HandleHttpRequestInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const newBody = {};
    const body = req.body;

    let resBody;
    resBody = handleRequest(body, newBody);

    let changedReq = req.clone({
      headers: req.headers
        .set('langID', '1')
    });

    changedReq = changedReq.clone({
      body: resBody
    });

    if (localStorage.getItem(ConstantsProject.TOKEN_KEY) && !changedReq.headers.has('skip-auth')) {
      changedReq = changedReq.clone({
        headers: changedReq.headers
          .set('Authorization', localStorage.getItem(ConstantsProject.TOKEN_KEY))
      });
    }

    if (changedReq.method === 'GET') {
      changedReq = changedReq.clone({
        headers: changedReq.headers.set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
      });
    }

    return next.handle(changedReq);
  }
}
