import { Component } from '@angular/core';
import { TemplateComponent as TemplateComponentBase } from '@web/base/core/template/template.component';

@Component({
  selector: 'web-project-template',
  templateUrl: './template.component.html',
  styleUrls: [
    './../../../base/core/template/template.component.scss',
    './template.component.scss'
]
})
export class TemplateComponent extends TemplateComponentBase {

}
