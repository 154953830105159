import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as fromApp from '../../../../../base/store/app.reducers';
import * as AuthActions from '../../../../../base/auth/store/auth.actions';
import { AuthService } from '../../../../../base/auth/auth.service';
import { RememberPasswordService } from '../remember-password/remember-password.service';
import { BaseComponent } from 'utils';
import { TranslateService } from '@ngx-translate/core';
import { ConstantsProject } from '@web/project/shared/constants.class';

@Component({
  selector: 'web-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends BaseComponent implements OnInit {

  public form: FormGroup;

  ELEMENT_TYPE_COLLEGIATE = ConstantsProject.ELEMENT_TYPE_COLLEGIATE;
  ELEMENT_TYPE_PRECOLLEGIATE = ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE;

  public userTypes = [
    { name: 'collegiates', value: ConstantsProject.ELEMENT_TYPE_COLLEGIATE },
    { name: 'precollegiates', value: ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE }
  ];

  constructor(
    public translate: TranslateService,
    public store: Store<fromApp.AppState>,
    public authService: AuthService,
    public rememberPasswordService: RememberPasswordService,
  ) {
    super(translate);
    this.authService.errorLogin = '';
  }

  ngOnInit() {
    this.getTranslations(['core']).then(() => {
      this.userTypes.map(t => {
        t.name = this.translations['core']['signin'][t.name];
        return t;
      });
    });

    this.form = new FormGroup({
      type: new FormControl(this.userTypes[0].value),
      username: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(1), Validators.maxLength(20)
      ])),
      password: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(60)]))
    });
  }

  openRememberPasswordDialog() {
    // this.dialogRef.close();
    this.rememberPasswordService.openDialog();
  }

  login() {
    if (this.form.status === 'VALID') {
      this.store.dispatch(new AuthActions.TrySignin(this.form.value));
    }
  }
}
