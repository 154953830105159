import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'baseTrueFalse' })
export class TrueFalsePipe implements PipeTransform {
    constructor(public translate: TranslateService) {
        translate.setDefaultLang('es');
    }

    transform(value) {
        return new Promise((resolve, reject) => {
            this.translate.getTranslation('es').subscribe(res => {
                resolve(res['general']['trueFalse'][value]);
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }
}
