import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { SafeHtmlPipe as SafeHtmlPipeBase } from '@web/base/shared/pipes/safeHtml.pipe';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe extends SafeHtmlPipeBase implements PipeTransform {
    constructor(public sanitized: DomSanitizer) {
        super(sanitized);
    }
}
