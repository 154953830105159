import { SharedService } from '@web/project/shared/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component, Input } from '@angular/core';
import { BaseWebComponent } from '@web/project/shared/components/base-web/base-web.component';

import { REDSYS_URL, REDSYS_VERSION } from '../../../../../environments/environment';

@Component({
  selector: 'web-base-payment-tpv',
  templateUrl: './payment-tpv.component.html',
  styleUrls: ['./payment-tpv.component.scss'],
})
export class PaymentTpvComponent extends BaseWebComponent implements OnInit {

  merchantParameters: string;
  signature: string;

  section = 'payment-tpv';
  showSidenav = true;

  breadcrumbs = [
    { label: 'paymentTpv', url: '' },
  ];

  REDSYS_VERSION = REDSYS_VERSION;
  REDSYS_URL = REDSYS_URL;

  constructor(
    translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public router: Router,
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  ngOnInit() {

    this.getTranslations(['paymentTpv']).then(() => {
      this.setMetas(this.translations['paymentTpv']['title']);
      this.breadcrumbs[0].label = this.translations['paymentTpv']['title'];

      this.setBreadcrumbs();
    });

  }
}
