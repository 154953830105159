import { OwlOptions } from 'ngx-owl-carousel-o';
import { invertColor } from '@web/base/shared/utils';
import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '@web/project/shared/shared.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListWebService } from './base-list-web.service';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { BaseWebComponent } from '@web/project/shared/components/base-web/base-web.component';
import { IBaseListWebItem } from '@web/project/shared/models/base-list-web-item.model';
import { IResponse } from '@web/project/shared/models/response.model';

@Component({
  templateUrl: './base-list-web.component.html',
  styleUrls: ['./base-list-web.component.scss'],
  providers: [BaseListWebService]
})
export class BaseListWebComponent extends BaseWebComponent implements OnInit {

  public invertColor = invertColor;

  DISPOSITION_LIST = ConstantsProject.DISPOSITION_LIST;
  DISPOSITION_GRID = ConstantsProject.DISPOSITION_GRID;
  DISPOSITION_JUMBOTRON = ConstantsProject.DISPOSITION_JUMBOTRON;
  DISPOSITION_CAROUSEL = ConstantsProject.DISPOSITION_CAROUSEL;

  itemKeyName: string;
  // categoryName: string;
  // categoryColor = '#999';
  showCategory = false;
  showCategoryText = true;
  showSectionTitle = true;

  showImage = true;

  baseUrl: Array<string> = []; // Ruta base que se utilizará para preparar el enlace al detalle de los elementos.

  disposition: number = ConstantsProject.DISPOSITION_LIST;

  formFilter: FormGroup; // Formulario utilizado para editar/crear el elemento
  isFiltering = false;

  truncate = 400; // Longitud, por defecto, del texto del item a mostrar en el listado.
  paginate = true;
  filter = false;
  quantity = 50;
  total: number;
  page = 0;

  privateCategory;
  private;

  category; // Ítem que representa la categoría

  public items$: Subject<Array<any>> = new Subject<Array<any>>();

  public customOptions: OwlOptions;

  constructor(
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
    public snackbar?: MatSnackBar,
    public dialog?: MatDialog,
    public meta?: Meta,
    public title?: Title,
    public route?: ActivatedRoute,
    public sharedService?: SharedService,
    public baseListWebService?: BaseListWebService,
    public router?: Router,
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      center: true,
      autoplay: true,
      items: 1,
      autoWidth: true
    };

    this.baseListWebService.setBase(this.section);

    if (this.filter) {
      this.createFormFilter();
    } else {
      this.getData();
    }
  }

  createFormFilter() {
    if (this.paginate) {
      this.formFilter = new FormGroup({
        page: new FormControl(0),
        quantity: new FormControl(this.quantity)
      });
    } else {
      this.formFilter = new FormGroup({});
    }

    this.getData(this.formFilter.value);
  }

  getData(filters?) {
    this.isFiltering = true;

    this.baseListWebService
      .load(filters, this.paginate, this.truncate)
      .subscribe(
        (response: IResponse) => {
          this.privateCategory = response.result.privateCategory;
          this.total = response.result.total;
          this.items$.next(
            response.result.items.map(i => {
              const image = i.files.find(f => f.name === 'image');
              i.image = image && image.url ? image.url : null;

              // Para usar la imagen como background
              i.backgroundImg = i.image
                ? this.sanitizer.bypassSecurityTrustStyle('url(' + i.image + ')')
                : this.sanitizer.bypassSecurityTrustStyle('url(./../../../../../assets/img/project/default.jpg)');

              return i;
            })
          );

          this.isFiltering = false;
        },
        err => {
          this.total = 0;
          this.items$.next([]);
          this.isFiltering = false;
        }
      );
  }

  loadPage(index: number) {
    this.formFilter.controls['page'].setValue(index - 1);
    this.page = index - 1;
    this.getData(this.formFilter.value);
  }

  // getPage(index: number) {
  //     this.loadPage.emit(index - 1);
  // }

  goToDetail(item: any) {
    // console.log(this.itemKeyName, id, this.baseUrl);
    this.router.navigate(this.baseUrl.concat([item[this.itemKeyName]]));
  }

}
