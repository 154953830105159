import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SimpleSnackbarComponent, BaseComponent } from 'utils';
import { ConstantsProject } from '@web/project/shared/constants.class';
import { AuthService } from '@web/base/auth/auth.service';

@Component({
  selector: 'web-remember-password',
  templateUrl: './remember-password.component.html',
  styleUrls: ['./remember-password.component.scss']
})
export class RememberPasswordComponent extends BaseComponent implements OnInit {

  public form: FormGroup;

  ELEMENT_TYPE_COLLEGIATE = ConstantsProject.ELEMENT_TYPE_COLLEGIATE;
  ELEMENT_TYPE_PRECOLLEGIATE = ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE;

  public userTypes = [
    { name: 'collegiates', value: ConstantsProject.ELEMENT_TYPE_COLLEGIATE },
    { name: 'precollegiates', value: ConstantsProject.ELEMENT_TYPE_PRECOLLEGIATE }
  ];

  constructor(
    public translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RememberPasswordComponent>,
    public authService: AuthService,
  ) {
    super(translate, snackbar, dialog);
  }

  ngOnInit() {

    this.getTranslations(['core']).then(() => {
      this.userTypes.map(t => {
        t.name = this.translations['core']['signin'][t.name];
        return t;
      });
    });

    this.form = new FormGroup({
      type: new FormControl(this.userTypes[0].value),
      number: new FormControl(null, [Validators.required, Validators.maxLength(50)])
    });
  }

  rememberPass() {
    if (this.form.valid) {
      this.subscriptions.push(this.authService.rememberPass(this.form.value).subscribe(
        () => {
          this.openSnackbar(this.translations['core']['signin'].rememberPasswordOK, SimpleSnackbarComponent.TYPE_OK);

          this.dialogRef.close();

          this.form.reset();
        },
        error => {
          this.openSnackbar(this.translations['core']['signin'].rememberPasswordError, SimpleSnackbarComponent.TYPE_ERROR);
        }
      )
      );
    }
  }

}
