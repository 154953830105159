import { BaseListWebService } from './../../project/shared/components/base-list-web/base-list-web.service';
import { GridContentComponent } from './home/grid-content/grid-content.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import * as CoreModuleBase from '../../base/core/core.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TemplateComponent } from './template/template.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../base/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthModule } from '@web/base/auth/auth.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CoreRoutingModule } from './core-routing.module';
import { SliderContentComponent } from './home/slider-content/slider-content.component';
import { ListContentComponent } from './home/list-content/list-content.component';
import { SigninService } from './header/dialogs/signin/signin.service';
import { SigninComponent } from './header/dialogs/signin/signin.component';
import { MenuMobileComponent } from './menu/menu-mobile/menu-mobile.component';
import { MenuMobileItemComponent } from './menu/menu-mobile/menu-mobile-item/menu-mobile-item.component';
import { CookiesConsentComponent } from './cookies/cookies-consent/cookies-consent.component';
import { CookiesService } from './cookies/cookies.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    RouterModule,
    HttpClientModule,
    CoreModuleBase.CoreModule,
    CoreRoutingModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    TemplateComponent,
    HomeComponent,
    SliderContentComponent,
    GridContentComponent,
    ListContentComponent,
    SigninComponent,
    MenuMobileComponent,
    MenuMobileItemComponent,
    CookiesConsentComponent
  ],
  exports: [
    CoreModuleBase.CoreModule,
    TemplateComponent
  ],
  entryComponents: [
    SigninComponent
  ],
  providers: [
    SigninService,
    BaseListWebService,
    CookiesService
  ]
})
export class CoreModule { }
