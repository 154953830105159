import { GALLERY_DATA, IMAGE_DATA, IMAGE_INDEX, GALLERY_COUNTER, IMAGE_ACTION_BUTTON } from './service/image-gallery-overlay.tokens';
import { ImageGalleryOverlayRef } from './image-gallery-overlay-ref';
import { Component, Inject } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { IImageGallery } from '@web/project/shared/models/image-gallery.model';
import { ImageGalleryComponent as ImageGalleryComponentBase} from '@web/base/shared/components/images-module/image-gallery/image-gallery.component';

const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'web-image-gallery',
  templateUrl: './../../../../../base/shared/components/images-module/image-gallery/image-gallery.component.html',
  styleUrls: ['./../../../../../base/shared/components/images-module/image-gallery/image-gallery.component.scss'],
  animations: [
    trigger('fade', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('* => fadeIn', animate(ANIMATION_TIMINGS))
    ]),
    trigger('slideContent', [
      state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ]),
    trigger(
      'interface', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        style({ opacity: 1 }),
        animate('50ms', style({ opacity: 0 }))
      ])
    ]
    )
  ]
})
export class ImageGalleryComponent extends ImageGalleryComponentBase {

  constructor(
    public dialogRef: ImageGalleryOverlayRef,
    @Inject(IMAGE_DATA) public image: IImageGallery,
    @Inject(GALLERY_DATA) public gallery: IImageGallery[],
    @Inject(IMAGE_INDEX) public imageIndex: number,
    @Inject(GALLERY_COUNTER) public counter: boolean,
    @Inject(IMAGE_ACTION_BUTTON) public actionButton: { title: string, color: string, callBackFunction: string }) {
      super(dialogRef, image, gallery, imageIndex, counter, actionButton);
    }
}
