import { Component, Input, ViewChild, ElementRef, Renderer2, OnInit, AfterViewInit } from '@angular/core';
// import { setInterval } from 'timers';

@Component({
  selector: 'web-base-images-fading',
  templateUrl: './images-fading.component.html',
  styleUrls: ['./images-fading.component.scss'],
})
export class ImagesFadingComponent implements OnInit, AfterViewInit {

  @Input() imageList: string[]; // Lista de URLs con las imágenes
  @Input() transition: number;  // en milisegundos
  @Input() fadein: number;      // en segundos

  @ViewChild('_imgMain', { static: false }) _imgMain: ElementRef;
  @ViewChild('_imgAux', { static: false }) _imgAux: ElementRef;

  imgMain: HTMLElement;
  imgAux: HTMLElement;
  imgMainElement;
  imgAuxElement;

  constructor(
    public renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.transition = this.transition || 10000;
    this.fadein = this.fadein || 2;
  }

  ngAfterViewInit() {
    this.imgMain = this._imgMain.nativeElement;
    this.imgAux = this._imgAux.nativeElement;
    this.imgMainElement = this.renderer.selectRootElement(this.imgMain);
    this.imgAuxElement = this.renderer.selectRootElement(this.imgAux);

    this.imgMain.style.backgroundImage = 'url(' + this.imageList[0] + ')';
    this.renderer.addClass(this.imgAuxElement, 'hidden');

    let i = 1;
    setInterval(() => {
      this.nextImage(i++ % this.imageList.length);
    }, this.transition);
  }

  nextImage(index) {
    this.imgAux.style.backgroundImage = 'url(' + this.imageList[index] + ')';
    this.renderer.removeClass(this.imgAuxElement, 'hidden');
    this.imgAux.style.animation = "fadein " + this.fadein + "s forwards";

    setTimeout(() => {
      this.imgMain.style.backgroundImage = 'url(' + this.imageList[index] + ')';
      this.renderer.addClass(this.imgAuxElement, 'hidden');
      this.imgAux.style.animation = "none";
    }, 1000 * this.fadein);
  }
}
