import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';
import { environment } from '../environments/environment';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

export class TranslationsLoader implements TranslateLoader {

  constructor(public http: HttpClient,
              public platform: any) {
  }

  public getTranslation(lang: string): Observable<any> {
    if (isPlatformServer(this.platform)) {
      return this.http.get(`${environment.ssrHost}/assets/i18n/${lang}.json`).pipe(shareReplay(1));
    } else if (!environment.production) {
      return this.http.get(`./assets/i18n/${lang}.json`).pipe(shareReplay(1));
    } else {
      return this.http.get(`${environment.ssrHost}/assets/i18n/${lang}.json`).pipe(shareReplay(1));
    }
  }
}

export function TranslationsLoaderFactory(http: HttpClient, platform: any): TranslateLoader {
  // return new TranslationsLoader(http, platform);


  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/base/', suffix: '.json' },
    { prefix: './assets/i18n/project/', suffix: '.json' },
  ]);
}
