export const environment = {
  production: true,
  ssrHost: 'https://dev-logopedasrioja-web.vfges.com'
};

export const RECAPTCHA_KEY = '6Lf06cIUAAAAAHX4lksKhc7q-orXpQRWPKPchdOG';

export const URL = 'https://dev-api-logopedasrioja.vfges.com/web';
export const publicS3Url = 'https://s3-eu-west-1.amazonaws.com/dev-public.logopedasrioja.vfges.com/';

export const REDSYS_URL = 'https://sis-t.redsys.es:25443/sis/realizarPago'; // XXXX = 25443
export const REDSYS_VERSION = 'HMAC_SHA256_V1';
