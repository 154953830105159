import { Component } from '@angular/core';
import { MenuMobileItemComponent as MenuMobileItemComponentBase } from '@web/base/core/menu/menu-mobile/menu-mobile-item/menu-mobile-item.component';

@Component({
  selector: 'menu-project-mobile-item',
  templateUrl: './menu-mobile-item.component.html',
  styleUrls: [
    // './../../../../../base/core/menu/menu-mobile/menu-mobile-item/menu-mobile-item.component.scss',
    './menu-mobile-item.component.scss'
  ]
})
export class MenuMobileItemComponent extends MenuMobileItemComponentBase {

}