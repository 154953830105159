import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'baseGetInitials'
})

export class GetInitialsPipe implements PipeTransform {
    transform(value) {
        let initials = '';
        if (value) {
            initials += value.charAt(0).toUpperCase();
        }
        return initials;
    }
}