import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Component, Renderer2 } from '@angular/core';
import { CalendarComponent as CalendarComponentBase } from '@web/base/shared/components/calendar/calendar.component';

@Component({
  selector: 'web-calendar',
  templateUrl: './../../../../base/shared/components/calendar/calendar.component.html',
  styleUrls: [
    './../../../../base/shared/components/calendar/calendar.component.scss',
    './calendar.component.scss'
  ]
})

export class CalendarComponent extends CalendarComponentBase {
  constructor(
    public translate: TranslateService,
    public router: Router,
    public renderer: Renderer2
  ) {
    super(translate, router, renderer);
  }
}
