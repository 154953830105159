import { Component } from '@angular/core';
import { FooterComponent as FooterComponentBase } from '@web/base/core/footer/footer.component';

@Component({
  selector: 'web-project-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './../../../base/core/footer/footer.component.scss',
    './footer.component.scss'
]
})
export class FooterComponent extends FooterComponentBase {

}
