import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HandleHttpResponseInterceptor as HandleHttpResponseInterceptorBase } from '@web/base/shared/interceptors/handle-http-response.interceptor';

@Injectable()
export class HandleHttpResponseInterceptor extends HandleHttpResponseInterceptorBase {

  constructor(public router: Router, public activatedRoute: ActivatedRoute) {
    super(router, activatedRoute);
  }
}
