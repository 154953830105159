import { VideoDialogComponent as VideoDialogComponentBase } from '@web/base/shared/components/videos-module/video-dialog/video-dialog.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'web-video-dialog',
  templateUrl: './../../../../../base/shared/components/videos-module/video-dialog/video-dialog.component.html',
  styleUrls: ['./../../../../../base/shared/components/videos-module/video-dialog/video-dialog.component.scss']
})
export class VideoDialogComponent extends VideoDialogComponentBase {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<VideoDialogComponent>) {
    super(data, dialogRef);
  }
}
