import { SharedService } from '@web/project/shared/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { PaymentTpvComponent as PaymentTpvComponentBase } from '@web/base/shared/components/payment-tpv/payment-tpv.component';

@Component({
  selector: 'web-payment-tpv',
  templateUrl: './../../../../base/shared/components/payment-tpv/payment-tpv.component.html',
  styleUrls: ['./../../../../base/shared/components/payment-tpv/payment-tpv.component.scss'],
})
export class PaymentTpvComponent extends PaymentTpvComponentBase {

  constructor(
    translate: TranslateService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    public meta: Meta,
    public title: Title,
    public route: ActivatedRoute,
    public sharedService: SharedService,
    public router: Router,
  ) {
    super(translate, snackbar, dialog, meta, title, route, sharedService, router);
  }
}
